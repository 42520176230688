<template>
  <div class="card">
    <div class="card-body">
      <form action="">
        <div class="form-row">
          <div class="col-md-4">
            <select v-model="search_data.staff_id" name="staff_id" id="staff_id" class="form-control form-control-sm" >
              <option :value="''">Select a staff</option>
              <option v-for="(staff, index) in staffs" :key="index"  :value="staff.id">{{ staff.name }} - {{ staff.phone_no }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
          </div>
        </div>
      </form>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-sm table-bordered text-center table-striped" id="staff_list">
          <thead>
          <tr>
            <th style="width: 5%">SL</th>
            <th>Staff Name</th>
            <th>Phone No.</th>
            <th>Leave Date Range</th>
            <th>Total Duration</th>
            <th>Leave Type</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(staff_leave_application, i) in staff_leave_applications" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ staff_leave_application.staff.name }}</td>
            <td>{{ staff_leave_application.staff.phone_no }}</td>
            <td>[ {{ staff_leave_application.start_date }} - {{ staff_leave_application.end_date }} ]</td>
            <td>{{ staff_leave_application.duration }}</td>
            <td>{{ staff_leave_application.staff_leave_type.name }}</td>
            <td>
              <a-button :loading="btnReject && i == index" v-if="staff_leave_application.status === 1" class="btn btn-danger btn-sm mr-2" @click.prevent="onWithdraw(staff_leave_application.id, index = i)"><i class="fa fa-times"></i> Withdraw</a-button>
            </td>
          </tr>
          </tbody>
        </table>
        <a-skeleton active :loading="loading"></a-skeleton>
        <h5 class="text-center text-secondary" v-if="staff_leave_applications.length === 0 && !flag">
          <Empty :image="simpleImage"></Empty>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      staffs: [],
      staff_leave_applications: [],
      btnReject: false,
      loading: false,
      index: -1,
      btnLoading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        staff_id: '',
      },
    }
  },
  mounted() {
    this.getCodes()
  },
  methods: {
    getCodes() {
      apiClient.get('/api/staff/codes')
        .then(response => {
          this.staffs = response.data.staffs
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.loading = true
      this.btnLoading = true
      this.flag = true
      apiClient.post('api/hub/staff/leave-application/search', this.search_data)
        .then(response => {
          this.staff_leave_applications = response.data.staff_leave_applications
        })
        .catch(error => {
          console.log(error)
        }).finally(() => {
          this.loading = false
          this.btnLoading = false
          this.flag = false
        })
    },
    onWithdraw(staffLeaveApplicationId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/hub/staff/leave-application/delete/' + staffLeaveApplicationId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.search()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    onApproved(staffLeaveApplicationId) {
      if (confirm('Do you really want to approved?')) {
        apiClient.post('api/hub/staff/leave-application/approved/', { staff_leave_application_id: staffLeaveApplicationId }).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.search()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
